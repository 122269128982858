import React from "react";

import Layout from "../../other/layout";
import SEO from "../../other/seo";

import Terms from "../../components/AC-Terms/Terms";

const cookiePage = () => {
  return (
    <Layout>
      <SEO title="Cookies" />
      <Terms />
    </Layout>
  );
};

export default cookiePage;



