import React, { useContext } from "react";
//import "./styles/CookiePage.css"
import { Link } from "gatsby";
import { useState } from "react";
//import FTbtn from "./FTbtn"
import icon from "../../assets/img/link-icon.png";
import { I18nContext } from "../../i18n";

function Terms(props) {
  const { translate } = useContext(I18nContext);
  const [onState, setOnState] = useState(true);

  const toggleHandler = () => {
    setOnState(!onState);
  };

  const saveLocalStorageHandler = () => {
    if (typeof localStorage !== undefined) {
      if (onState) {
        let consent = {
          date: new Date(),
          consent: "Manage-Cookie: ON",
          isAccept: true
        };
        localStorage.setItem("cookieAcceptance", JSON.stringify(consent));
      } else {
        let consent = {
          date: new Date(),
          consent: "Manage-Cookie: OFF",
          isAccept: false
        };
        localStorage.setItem("cookieAcceptance", JSON.stringify(consent));
      }
    }
  };

  return (
    <div
      className="cookiePage"
      style={{ width: "80%", margin: "10%", marginTop: "0", marginBottom: "0" }}
    >
      <h1 style={{ color: "#0070CC" }}>Universal Terms of Use</h1>
      <h2>1. Acceptance of Terms</h2>
      {/* <div className="first-para"> */}
      <p>
        Please read these Universal Terms of Use (“TOU”) carefully as they
        govern your access and use of this website (“Site”) and the products and
        services provided herein (“Products” and/or “Services”). These TOU are
        in addition to (not in lieu of) any specific terms and policies that
        apply to the Products or Services you purchase or access through the
        Site, which terms and policies are incorporated herein by reference. The
        TOU and specific terms and policies referenced herein are collectively
        the “Agreement”.
      </p>
      <br></br>
      <p>
        NEBS Business Productions Ltd. (“Deluxe Canada”) maintains the{" "}
        <a href="https://www.deluxe.ca/canEcat/shop/">www.deluxe.ca</a> website.
        As used herein, “Deluxe”, “we”, “us”, “our” or “ours” refers to Deluxe
        Canada and its affiliates. “Customer”, “Account Holder”, “you”, “your”
        or “yours” refers to the customer accessing this Site and purchasing and
        using the Products or Services together with any company or other
        business entity you are representing, if any.
      </p>
      <br></br>
      <p>
        We reserve the right to make changes to the Site, the Products or
        Services and these TOU at any time without prior notice to you. You
        should therefore review these TOU each time you access this Site to keep
        apprised of these changes. If you do not agree to the changes
        implemented by Deluxe Canada, your sole and exclusive remedy is to
        terminate your relationship with us as a customer of the Products or
        Services. Unless explicitly stated otherwise, any new features or
        products that change, augment or enhance Deluxe Canada’s Products or
        Services offerings will be subject to this Agreement.
      </p>
      <hr />
      <h2>2. Use of the Site</h2>
      <p>
        You are granted permission to access and use this Site and its Content
        (as defined below) for the sole purpose of preparing, evaluating, and
        purchasing Deluxe Canada’s Products and Services through the Site. No
        other download, retention, use, publication, or distribution of any
        portion of the Content is allowed.
      </p>{" "}
      <br></br>
      <p>
        Deluxe Canada assumes no liability or responsibility for any
        inaccuracies, errors or omissions in any Content. Deluxe Canada also
        assumes no responsibility and shall not be liable for any damages to, or
        viruses that may infect, your computer equipment or other property on
        account of your access to, use of, or browsing on the Site or your
        downloading any materials, data text or images from the Site.
      </p>{" "}
      <br></br>
      <p>
        You agree to use this Site and our Products and Services in a
        responsible manner that is in compliance with these TOU and your local
        laws and regulations, including export and import regulations.
      </p>{" "}
      <br></br>
      <p>
        By accessing, using and placing orders on the Site, using any of our
        Products or Services and/or electronically accepting any of our
        Agreements, you represent and warrant that you are at least 18 years of
        age and/or are otherwise recognized as being able to form legally
        binding contracts under applicable law, If you are agreeing to these TOU
        or any of our other Service Agreements on behalf of a corporate entity,
        you represent and warrant that you have the legal authority to similarly
        bind such corporate entity.
      </p>{" "}
      <br></br>
      <p>
        By accessing, using and placing orders on the Site, you warrant that you
        have all necessary permission, right and authority to do so and you
        authorize Deluxe Canada to produce the Products you order. You also
        agree to be bound by these TOU, our{" "}
        <a href="../../privacy-policy">Privacy Policy</a> and to any additional
        related policies, guidelines, restrictions or rules that may be posted
        from time to time. All such additional posted guidelines, restrictions,
        or rules are incorporated herein by reference.
      </p>{" "}
      <br></br>
      <p>
        Any communication or material you transmit to the Site by electronic
        mail or otherwise, including any data, questions, comments, suggestions,
        or the like is, and will be treated as, non-confidential and
        non-proprietary, except as required by law and our{" "}
        <a href="../../privacy-policy">Privacy Policy</a>. Anything you transmit
        or post may be used by us or our affiliates for any purpose, including
        but not limited to reproduction, disclosure, transmission, publication,
        broadcast and posting. Furthermore, we are free to use, without
        limitation or restriction, any ideas, concepts, know-how or techniques
        contained in any communication you send to or through the Site for any
        purpose whatsoever, including but not limited to developing,
        manufacturing and marketing Products and Services using such
        information.
      </p>
      <br></br>
      <h3>Site Pricing</h3>
      <p>
        Deluxe Canada strives to keep our Site up to date; however, occasional
        fluctuation in market conditions may change the price of a product. Our
        goal is to honor the pricing shown on the Site, however, we reserve the
        right to change published prices of product offered on our Site at any
        time. If a published Site price is incorrect on an item you have quoted
        or ordered, we will contact you immediately with the most updated price
        or help you choose a different item that is within your budget.
      </p>{" "}
      <br></br>
      <h3>Site Images</h3>
      <p>
        Deluxe Canada makes every effort to provide clear and color-correct
        product images on our Site that best reflect the true nature of the
        products. However, website images are inherently limited in their
        ability to accurately show color, scale, and detail. Please be careful
        about making assumptions about products from the Site image alone.
      </p>{" "}
      <br></br>
      <hr />
      <h2>3. Your Account, Password and Security</h2>
      <p>
        In order to transact a purchase on the Site you must first complete our
        registration process where you will provide us with your billing and
        contact information (“Your Account”). You agree to provide and maintain
        accurate, current and complete Your Account information about you, and
        any entity on whose behalf you order Products or Services.
      </p>{" "}
      <br></br>
      <p>
        You are responsible for maintaining the confidentiality of Your Account
        and are responsible for all activities that occur and orders that are
        placed under Your Account. You will immediately notify us of any
        unauthorized use of Your Account or any other breach of security and
        ensure that you exit from Your Account at the end of each session. We
        may suspend or terminate Your Account upon notice to you if we
        reasonably determine that Your Account has been involved in a violation
        of this Agreement. You will cooperate with us with respect to
        investigations by us of any suspected or alleged violation of this
        Agreement and any action undertaken by us to enforce this Agreement. You
        will be liable for the losses incurred by us or others due to any
        unauthorized use of Your Account. Deluxe Canada will not be liable for
        any loss or damage arising from your failure to comply with this
        section.{" "}
      </p>{" "}
      <br></br>
      <hr />
      <h2>4. Notice</h2>
      <p>
        We may provide communications and notices to you by means of a general
        notice on the Site or by email to the address on record in Your Account
        or by written communication sent by first class mail or prepaid post to
        your address on record. Such notice shall be deemed effective within
        forty-eight (48) hours of transmission by mail or within twelve (12)
        hours of transmission by email or by notice on the Site. You may
        withdraw your consent to receive electronic communications, however
        doing so may also require that you discontinue your use of the Services.
        You may provide notice to us by sending an e-mail to{" "}
        <a href="mailto:cdncustserv@deluxe.ca">cdncustserv@deluxe.ca</a> or by
        sending a written notice by first class mail or prepaid post to:
        Attention: Customer Service, Deluxe Canada, 330 Cranston Crescent
        Midland, ON L4R 4V9 . Such notice shall be deemed effective when
        received by us.
      </p>{" "}
      <br></br>
      <hr />
      <h2>5. Third Party Information and Links to Other Websites</h2>
      <p>
        The Site and/or the Products or Services may contain products, services,
        content, information and links to and from third party providers (such
        as advertisers and affiliates) and their websites ("Third Party
        Information"). You may be subject to additional and/or different terms,
        conditions, and privacy policies when using or accessing Third Party
        Information. Deluxe Canada is not responsible for, disclaims all
        liability for and makes no representations or warranties for Third Party
        Information. Although we do not have an obligation to do so, we reserve
        the right to pre-screen Third Party Information.
      </p>
      <br></br>
      <hr />
      <h2>6. Intellectual Property</h2>
      <p>
        {" "}
        You should assume that everything you see or read on the Site is
        copyrighted unless otherwise noted and may not be used except as
        provided herein without our prior written permission. Except as noted
        otherwise in the text of the Site, the trademarks, logos, service marks,
        text, images and all other copyrightable materials displayed on the Site
        and available for use with the Products or Services constitute
        intellectual property owned by Deluxe Canada or which Deluxe Canada has
        the right to use on the Site (the “Content”). Nothing herein grants you
        any license or right, by implication, estoppel or otherwise, to use any
        Content without our prior written permission. Unauthorized use of the
        Content or any other content on the Site is strictly prohibited. You
        acknowledge that Content is being provided merely as a convenience and
        accommodation to you on an “AS IS” basis without warranty of any kind.
      </p>
      <br />
      <hr />
      <h2>7. Materials Provided by You</h2>
      <p>
        In connection with your use of the Site and the purchase of Products or
        Services made available through the Site, you may provide us with text,
        images, photographs, graphics, sound, video and other information for
        inclusion (“User Content”). You may also have the ability to view, post,
        publish, share, store or manage User Content via the Site or the
        Products or Services. All such comments and postings are public, not
        private, communications.
      </p>
      <br />
      <p>
        Although we are not obligated to pre-screen User Content, we reserve the
        right to do so or to refuse or remove any of User Content that, in our
        sole discretion, violates this Agreement or is otherwise objectionable
        in our sole discretion.
      </p>
      <br />
      <p>
        You agree to back-up all of your User Content so that you can access and
        use it when needed. Deluxe Canada does not warrant that it backs-up User
        Content, and you agree to accept as a risk the loss of any and all of
        your User Content. You agree to indemnify and hold Deluxe Canada and its
        subsidiaries and affiliates and its and their officers, directors,
        employees, partners and agents, harmless from any claim or demand,
        including reasonable attorneys' fees, made by any third party due to or
        arising out of our use of User Content.
      </p>
      <br />
      <hr />
      <h2>8. Purchases of Products and/or Services; Transfer of Title</h2>
      <p>
        {" "}
        Prices are F.O.B. shipping point. This means that the risk of loss and
        title for Products you order from us pass to you upon our delivery to
        the carrier. For any Product or Service that is to be provided to you in
        an electronic format, delivery shall be deemed to have occurred either
        (a) at the time we transmit the Product via email or other electronic
        communication addressed to you, or (b) at the time we transmit a
        notification to you that the Product is available for downloading from
        the Site. Provincial Sales Tax (PST), Quebec Sales Tax (QST), Goods &
        Services Tax (GST) and Harmonized Sales Tax (HST) will be added to your
        order where applicable. If you are exempt from sales tax, please provide
        us with a copy of your resale exemption certificate.
      </p>{" "}
      <br></br>
      <p>
        Submission of an online order and/or your signature on our Order
        Acknowledgment is a conditional acceptance by Deluxe Canada of your
        offer to purchase our goods and your acceptance of our terms and
        conditions. It may contain terms that differ from or add to those
        contained in your purchase order, should you have one, and to the extent
        that this is the case, Deluxe Canada hereby expressly conditions its
        acceptance of your offer on Deluxe Canada’s agreement of the additional
        or different terms. Your receipt and retention of the goods covered by
        this invoice shall constitute acceptance of any such additional or
        different terms.{" "}
      </p>
      <br></br>
      <p>Changes/Adjustments</p>
      <br></br>
      <p>
        Once a print job has been approved by you and "sent to press" on the
        Site, no changes are allowed to the artwork files, job characteristics,
        or printing turnaround time. After a print job has been sent to Deluxe
        Canada’s prepress department, you are responsible for paying the entire
        amount of that print job along with applicable taxes, shipping, and
        processing fees, unless otherwise noted.
      </p>
      <br></br>
      <p>Order cancellation Terms and Conditions </p>
      <ul>
        <li>
          When you approve your order, we start preparing your proof, at which
          point the pre-production process begins.
        </li>
        <li>
          Once you have approved your virtual proof, your order is sent to the
          manufacturer and the production process begins.
        </li>
        <li>
          We cannot guarantee our ability to make changes or cancellations to an
          order once the production process begins. All completed work and costs
          incurred will be your responsibility.
        </li>
        <li>
          You are liable to pay the agreed upon price for any work that may have
          been completed up to the time that we are able to cancel/change your
          order. These charges may include, but are not limited to, setup,
          artwork preparation, shipping charges and restocking fees. All
          completed work and costs incurred will be your responsibility.
        </li>
      </ul>
      <br></br>
      <p>Trial Offers</p>
      <p>
        Some of our Products and Services are offered on a free or limited trial
        basis. Unless otherwise stated, limit one free or limited trial offer
        per person or entity during any one-year period. If you do not cancel
        your trial subscription during the offer period, we will charge the
        credit card you provided during the registration process the applicable
        monthly subscription fee.
      </p>
      <br></br>
      <hr />
      <h2>9. Fees and Payments</h2>
      <p>
        You agree to pay any and all fees and payments due for Products or
        Services purchased at this Site at the time they are ordered. All fees
        and payments due are in Canadian dollars and are non-refundable unless
        otherwise expressly noted, even if your Services are suspended,
        terminated, or transferred prior to the end of the Services term.{" "}
      </p>{" "}
      <br></br>
      <p>
        Unless otherwise stated, you may pay for Products and Services by
        providing a valid credit card or ACH if available. You acknowledge and
        agree that it is your sole responsibility to modify and maintain Your
        Account settings, including but not limited to (i) setting your renewal
        options and (ii) ensuring that your credit card or ACH information is
        current and valid. Failure to do so may result in the interruption or
        loss of Services. Deluxe Canada will not be liable to you or any
        third-party regarding Services loss or interruptions. You must notify us
        of any billing problems or discrepancies within fifteen (15) days after
        they first appear on your credit card or bank account statement,
        otherwise you waive any right to dispute any such discrepancy.
      </p>
      <br></br>
      <p>
        You acknowledge that Deluxe Canada may use the services of a third party
        to automatically update your credit card expiration date. These
        recurring billing or account updating programs “Billing Programs” are
        supported by your credit card provider (and are ultimately dependent on
        your bank’s participation). If you are enrolled in an automatic renewal
        option and we are unable to successfully charge your existing payment
        method, your credit card provider (or your bank) may notify us of
        updates to your credit card number and/or expiration date, or they may
        automatically charge your new credit card on our behalf without
        notification to us.
      </p>
      <br></br>
      <p>
        If we are unable to charge your credit card or bank account for the full
        amount owed for the Products or Services provided or if we incur a
        chargeback for any fee we previously charged to your credit card, you
        acknowledge that Deluxe Canada may pursue all available lawful remedies
        in order to obtain payment including, immediate cancellation without
        notice to you of any domain names or Services registered or renewed on
        your behalf.
      </p>
      <br></br>
      <p>
        Deluxe Canada reserves the right to charge you reasonable additional
        fees for, e.g. (i) service upgrades (one-time non-recurring charge) to
        be invoiced to you in the following billing cycle, (ii) tasks we may
        perform for you that are outside the normal scope of our Services, (iii)
        additional time and/or costs we may incur in providing our Products and
        Services to you, and/or (iv) your noncompliance with this Agreement as
        determined by us in our sole discretion. These additional fees will be
        billed to the credit card or other payment method you have on file with
        us.
      </p>
      <br></br>
      <p>
        Some of Deluxe Canada’s Services offer an automatic renewal option that
        will automatically renew your service for a renewal period equal in time
        to the original service period. For example, if your original service
        period is for one year, your renewal period will be for one year. While
        the details of the automatic renewal option vary from Service to
        Service, the Services that offer an automatic renewal option treat it as
        the default setting. Therefore, unless you cancel prior to the automatic
        renewal, Deluxe Canada will automatically renew the applicable service
        when it comes up for renewal and will take payment from the payment
        method you have on file with us. Renewal fees will be charged at Deluxe
        Canada’s then-current rates.
      </p>
      <br></br>
      <p>
        Deluxe Canada expressly reserves the right to change or modify its
        prices and fees at any time, and such changes or modifications will be
        posted online at this Site or otherwise communicated to you using the
        information on file with us and as required by law, and effective
        immediately without further notice to you. If you have purchased
        Products or Services for a fixed time period, changes or modifications
        in prices and fees will be effective when the Product or Service in
        question comes up for renewal. If you find any pricing change
        unacceptable, you may cancel your Service subscription(s) and terminate
        your Customer Account, however Deluxe Canada will not be obligated to
        refund any remaining portion of your pre-paid fees.
      </p>
      <br></br>
      <p>
        If a Service is offered with a special promotion price or period offered
        by us, you agree that all subsequent periods after the initial promotion
        period will be billed at the then stated list price for the service.
      </p>
      <br></br>
      <hr />
      <h2>10. Shipping and Delivery</h2>
      <br></br>
      <ul>
        <li>
          Deluxe Canada is not liable for incorrect shipping address,
          undeliverable packages/3rd delivery attempts, or other errors created
          by you.
        </li>
        <li>
          Deluxe Canada is not liable for damage to the products arising after
          delivery to you.
        </li>
        <li>Satisfaction guarantee does not apply to shipping claims.</li>
        <li>
          We reserve the right to have you return the original order before
          reprinting or refunding your order.
        </li>
        <li>
          We are not responsible for any damages or claims incurred by you or
          your business caused by receiving printed materials which are
          defective or incomplete or which you receive later than the estimated
          delivery date.
        </li>
        <li>
          We can ship most products via USPS to APO or FPO addresses, please
          call for a shipping quote.
        </li>
        <li>
          Split shipments can generally be accommodated. Be advised, however,
          that you will be subject to additional charges. Please call to
          inquire.
        </li>
        <li>
          We make every attempt to have your merchandise ship from the factory
          on or before the date specified in your Order Confirmation or desired
          due date, in the event that there is a delay you will be notified
          immediately. We typically provide tracking information within 24 hours
          of shipment.
        </li>
        <li>
          Orders of excessive size or weight may be shipped via common carrier
          (freight line); you will be notified if this is necessary.
        </li>
        <li>
          We ship from various manufacturing facilities throughout the United
          States. If your order contains multiple items, these items may arrive
          in separate shipments.
        </li>
        <li>Please be advised that we are unable to ship to PO boxes.</li>
        <li>
          Some items cannot be shipped to APO addresses per manufacturer, please
          call and we can verify if APO delivery is possible. Special pricing
          may apply.
        </li>
        <li>
          All products become your property at the time they are accepted by the
          shipping service.
        </li>
      </ul>
      <br></br>
      <p>
        {" "}
        Shipping and Arrival Times <br></br>
        Shipping transit times vary, and Deluxe Canada assumes no responsibility
        for delays caused by acts of god, shipping carriers, weather,
        circumstances beyond our direct control, or any damages resulting from
        the failure to receive an order on time. Your order may arrive late due
        to unforeseen delays in delivery service, the breakdown of equipment,
        illness, etc.
      </p>
      <br></br>
      <p>
        Target arrival dates are calculated by adding the project turnaround
        time to the shipping transit time. Both production and shipping times
        are based on business days only and do not include weekends or US or
        Canadian holidays. Shipment and delivery dates are the best estimates
        and Deluxe Canada is not liable for any consequential or special damages
        arising from any delay in delivery.
      </p>
      <br></br>
      <p>
        Shipping Charges
        <br></br>
        Deluxe Canada is committed to estimating shipping charges as accurately
        as possible. However, on rare occasions, we may underestimate or
        overestimate the shipping charges on your order. In such an event we may
        make reasonable authorized adjustments to your shipping charges. You
        will always be contacted about additional charges. Refusal of charges
        may delay delivery of your order.
      </p>
      <br></br>
      <hr />
      <h2>11. Term and Termination</h2>
      <p>
        The term and termination provisions of this Agreement and any applicable
        Services terms of use vary according to the Services selected by you
        when ordering and will be presented to you during the Services selection
        and ordering process. Deluxe Canada reserves the right in its sole
        discretion to immediately terminate the Service for reasonable cause,
        including but limited to: (i) non-payment to us; (ii) failure to meet
        our credit requirements; (iii) non-compliance with any of the provisions
        of this Agreement or any Services terms of use; (iv) requests by law
        enforcement or other government agencies; (v) our ceasing to offer the
        Service; (vi) our inability to verify or authenticate any information
        you provide to us; or (vii) our conclusion, in our sole discretion, that
        your use of or access to the Service may result in liability to us. In
        the event of default by you, any and all payments required to be made to
        us by you shall be due and payable immediately. Termination of this
        Agreement shall not relieve you from any liability, including amounts
        owing, accrued prior to the time that such termination becomes
        effective.
      </p>
      <br></br>
      <hr />
      <h2>12. No-risk guarenttee</h2>
      <p>
        Your products are our priority; Deluxe Canada works hard to make sure
        you get them on time, just the way you ordered them. You have
        expectations. To meet them, we are happy to refund (in accordance to our
        return policy below) or reprint your merchandise if it’s different than
        the proof you approved.
      </p>
      <br></br>
      <p>
        Deluxe strives to make running a business or planning an event easier
        for you by having your back. We want to make sure you’re happy with your
        purchase because we want you to be as thrilled to choose us as we are to
        have you as a customer.
      </p>
      <br></br>
      <p>
        Please note: To issue a refund or reprint, we may need to arrange a
        pickup of your damaged or defective order. To make this a smooth
        process, you might be asked to show documentation. We’re happy to assist
        you, so before you toss out or return your purchase, give us a call so
        we can find a solution.
      </p>
      <br></br>
      <p>
        One thing we’d like you to keep in mind is that all sales, unless
        otherwise noted, are final. Deluxe Canada puts special care into working
        on your job and will not be able to give a refund once we’ve gotten the
        materials and begun working.
      </p>
      <br></br>
      <p>
        Life is unpredictable, and some circumstances are beyond our control. We
        cannot be held responsible for:
      </p>
      <ul>
        <li>
          Spelling, punctuation or grammatical errors you make during your
          customization process
        </li>
        <li>Poor image quality or low-resolution of your uploaded files</li>
        <li>
          Design or color selection errors resulting from what you submitted
          during the file creation process
        </li>
        <li>
          Errors in user-selected options like product type, size, finishing
          options or quantity
        </li>
        <li>
          Incorrect dimensions, image orientation or file submission in
          accordance with our specifications
        </li>
        <li>Color-match guarantee without a hard-copy proof approval</li>
        <li>Delivery delays due to improperly prepared files</li>
        <li>Duplicate orders by the customer</li>
        <li>
          Incorrect file layout for folding, scoring, hole drilling, die cutting
          or other custom services
        </li>
      </ul>
      <p>
        <span style={{ fontSize: "0.9em" }}>
          Don’t let this list overwhelm you. We’ve got steps in place all along
          the process to help prevent these errors from happening.
        </span>
      </p>
      <br></br>
      <p>
        Claims and returns
        <br />
        If problems arise, please reach out to us within 15 days of receiving
        your package. A good way to meet this time frame is to open your order
        right away, even if you don’t plan on using it for a while. After 15
        days, it may not be possible to go back to our suppliers for credit.
        Having your order number available when contacting us will make finding
        a solution simpler on both ends. Unauthorized returns are not accepted.
        Customer service is always here to help and, if need be, we’ll give you
        a Return Material Authorization number (RMA) and a special shipping
        address to send your package on a journey back to us.
      </p>
      <br></br>
      <div style={{ height: "60px" }}></div>
    </div>
  );
}

export default Terms;
